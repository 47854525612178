'use client';
import ActionButton from '@/components/ActionButton';
import PrimeryHeading from '@/components/core/PrimeryHeading';
import CrossIcon from '@/components/svgs/CrossIcon';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import './style.css';

const OTPVerify = ({
    otpVerification,
    setOtpVerification,
    closeOtpVerification,
}) => {
    const [
        {
            otp,
            numInputs,
            separator,
            minLength,
            maxLength,
            placeholder,
            inputType,
        },
        setConfig,
    ] = useState({
        otp: '',
        numInputs: 4,
        separator: '',
        minLength: 0,
        maxLength: 40,
        placeholder: '0',
        inputType: 'number',
    });

    const [timer, setTimer] = useState(59);
    const handleOTPChange = (otp) => {
        setConfig((prevConfig) => ({ ...prevConfig, otp }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
    };

    const handleNumInputsChange = (event) => {
        let numInputs = event.target.valueAsNumber;

        if (numInputs < minLength || numInputs > maxLength) {
            numInputs = 4;

            console.error(
                `Please enter a value between ${minLength} and ${maxLength}`,
            );
        }

        setConfig((prevConfig) => ({ ...prevConfig, numInputs }));
    };

    const clearOtp = () => {
        setConfig((prevConfig) => ({ ...prevConfig, otp: '' }));
    };

    const handleSubmit = async (event) => {
        if (!otp) return;
        // check otp
        setOtpVerification(async (prev) => {
            console.log('OTP{ Handler Calls');
            await prev.handlerFn(otp);

            return prev;
        });
    };

    const resendOTP = () => {};
    useEffect(() => {
        const intervalInstance = setInterval(() => {
            setTimer((prev) => {
                if (prev > 0) {
                    return prev - 1;
                }
                clearInterval(intervalInstance);
                return 0;
            });
        }, 1000);

        return () => {
            clearTimeout(intervalInstance);
        };
    }, []);

    return (
        <div className="p-8  flex flex-col ">
            <div className="mb-8 select-none max-w-maxContentTab mx-auto inline-block">
                <button className="mb-32" onClick={closeOtpVerification}>
                    <CrossIcon></CrossIcon>
                </button>
                <PrimeryHeading
                    className={'text-gray-600 !text-xl font-giest-medium mb-2 '}
                >
                    Verification Code
                </PrimeryHeading>
                <p className="font-giest-light text-gray-400">
                    Lorem ipsum dolor sit amet consectetur adipisicing Lorem,
                    ipsum dolor.
                </p>
            </div>
            <div className="flex flex-col max-w-maxContentTab mx-auto">
                <OTPInput
                    inputStyle="inputStyle"
                    numInputs={numInputs}
                    onChange={handleOTPChange}
                    renderSeparator={<span>{separator}</span>}
                    value={otp}
                    placeholder={placeholder}
                    inputType={inputType}
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus
                    containerStyle={'flex w-full gap-x-3 '}
                />
                <div className="w-full max-w-maxContentTab mx-auto">
                    <div className="flex justify-end">
                        <button
                            className={clsx(
                                'text-sm text-blue-600 mt-3 rounded-full text-right px-3 w-fit',
                                timer < 1 && ' cursor-pointer hover:underline',
                            )}
                            onClick={resendOTP}
                        >
                            {timer > 1 && `Resend in ${timer}s`}
                            {timer < 1 && `Resend OTP`}
                        </button>
                    </div>

                    <div className="px-0 w-full">
                        <ActionButton
                            className={
                                '!text-base text-center py-3 mt-8 md:mt-8 w-full !rounded-2xl'
                            }
                            onClick={handleSubmit}
                        >
                            Confirm
                        </ActionButton>
                    </div>
                </div>
            </div>
            <p className="text-sm text-gray-500 font-giest-light absolute bottom-0 py-6 text-center w-full left-0">
                OTP (One Time Password) is valid till 5 minutes only.
            </p>
        </div>
    );
};

export default OTPVerify;
