import ChevronDown from '@/components/svgs/ChevronDown';
import clsx from 'clsx';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
const LinkItem = ({ children, subMenu, className }) => {
    const [isOpenLink, setIsOpenLink] = useState(false);
    const handleCloseSubMenu = () => {
        setIsOpenLink(false);
    };
    // Clone the subMenu element to add the handleCloseSubMenu prop
    const clonedSubMenu = React.cloneElement(subMenu, {
        handleCloseSubMenu,
    });

    return (
        <>
            <button
                className={clsx(
                    'py-6 px-6 rounded-2xll borderr borderr-gray-300 hover:scale-[0.97] hover:bg-gray-100 flex items-center justify-between transition-all w-full text-sm text-gray-600 select-none sm:rounded-2xl',
                    className,
                )}
                onClick={() => setIsOpenLink(true)}
            >
                <div className="!font-giest-light">{children}</div>
                <ChevronDown className="-rotate-90 text-lg !text-gray-400 fill-gray-600"></ChevronDown>
            </button>
            {/* Sub Menu */}
            <CSSTransition
                in={isOpenLink}
                classNames={'sub_menu'}
                timeout={800}
                appear
                unmountOnExit
            >
                {clonedSubMenu}
            </CSSTransition>
        </>
    );
};

export default LinkItem;
