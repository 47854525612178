"use client";

import normalFilter from "@/data/NormalFilter";
import { usePathname, useSearchParams } from "next/navigation";
import React, { Suspense, useCallback, useState } from "react";
import Modal from "../Modal";
import "@/styles/radioInput.css";
import FilterModalChild from "./filterModalChild";
// import NormalFilter from "../../data/NormalFilter";

const FilterModal = ({ handleCloseModal }) => {
  // filter configurations
  const [filterValue, setFilterValue] = useState(normalFilter);
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const filterValueQuery = searchParams.get("filterValue");

  const handleFilter = useCallback(
    (e, item) => {
      setFilterValue({
        [e.target.id]: e.target.checked,
        name: e.target.value,
      });
      const urlSearchParams = new URLSearchParams(searchParams);
      urlSearchParams.set("filterValue", item.name);
      history.replaceState(
        null,
        "",
        `${pathname}?${urlSearchParams.toString()}`
      );
      handleCloseModal();
    },
    [searchParams, pathname, handleCloseModal]
  );

  return (
    <Modal
      title={
        <div className="flex items-center gap-x-2 GTE_light">
          Filter Options
        </div>
      }
      setModal={() => handleCloseModal()}
    >
      <FilterModalChild
        handleFilter={handleFilter}
        filterValueQuery={filterValueQuery}
        filterValue={filterValue}
      ></FilterModalChild>
    </Modal>
  );
};

export default FilterModal;
