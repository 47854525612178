import LeftArrow from '@/components/svgs/LeftArrow';
import './linkItem.css';
const SubMenu = ({ children, title, handleCloseSubMenu }) => {
    return (
        <div className="p-8 w-full h-full sub_menu drop-shadow-lg">
            <div
                className="float-left cursor-pointer"
                onClick={handleCloseSubMenu}
            >
                <LeftArrow></LeftArrow>
            </div>

            <h2 className="text-center text-base text-gray-800 font-giest-light">
                {title}
            </h2>
            <div className="w-full max-w-maxContentTab mx-auto">
                <div
                    className="mt-6 font-giest-light h-[calc(100dvh_-_4rem_-_24px)] overflow-auto pb-4"
                    onLoad={() => console.log('loadede....')}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SubMenu;
