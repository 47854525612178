'use client';
import BottomSheet from '@/components22/common/BottomSheet';
import Login from '@/components22/common/Navbar/auth/Login';
import OTPVerify from '@/components22/common/Navbar/auth/OTPVerify';
import Signup from '@/components22/common/Navbar/auth/Signup';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';
import ChevronDown from '../svgs/ChevronDown';
import { useMediaQuery } from '@/hooks';

const LogInSignUpButtons = ({ setUserData }) => {
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [otpVerification, setOtpVerification] = useState({
        isOpen: false,
        handleFn: () => {},
        isVerified: false,
        otp: null,
        email: null,
    });
    const [verifiedUserId, setVerifiedUserId] = useState(null);
    const isWindow = useMediaQuery('(min-width:700px)');

    const closeOtpVerification = () => {
        console.log('calling...');
        setOtpVerification((prev) => ({ ...prev, isOpen: false }));
    };
    // console.log('updting...', otpVerification);
    return (
        <>
            <button
                href="/auth/login"
                className="text-[13px] font-giest-light rounded-full py-[6px] px-3 text-white bg-[#ff612c] hover:bg-white hover:border-[#ff612c] hover:text-[#ff612c]"
                onClick={() => setOpenAuthModal(true)}
            >
                Sign Up
            </button>
            <BottomSheet
                height={isSignUp ? 'auto' : 'auto'}
                isOpen={openAuthModal}
                handleCloseModal={() => setOpenAuthModal(false)}
                setIsOpen={setOpenAuthModal}
                isBlockMode={isWindow}
                blockSideClick={isWindow}
                className={clsx(isWindow && "!max-w-[460px] ")}
            >
                <div
                    className={clsx(
                        'h-full overflow-auto !py-5 ',
                        isSignUp && 'pb-6',
                    )}
                >
                    <div className="float-right px-6 sticky top-0">
                        <div
                            className="w-[30px] h-[30px] rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
                            onClick={() => setOpenAuthModal(false)}
                        >
                            <ChevronDown></ChevronDown>
                        </div>
                    </div>
                    <div>
                        <Image
                            src={'/profile/amfeza_logo.png'}
                            width={90}
                            height={65}
                            className="w-[100px] ml-6"
                            alt="amfeza_logo"
                        ></Image>
                    </div>
                    {isSignUp && (
                        <Signup
                            setIsSignUp={setIsSignUp}
                            setOtpVerification={setOtpVerification}
                            otpVerification={otpVerification}
                            setVerifiedUserId={setVerifiedUserId}
                            verifiedUserId={verifiedUserId}
                        ></Signup>
                    )}
                    {!isSignUp && (
                        <Login
                            setIsSignUp={setIsSignUp}
                            setOtpVerification={setOtpVerification}
                            closeOtpVerification={closeOtpVerification}
                            setOpenAuthModal={setOpenAuthModal}
                            setUserData={setUserData}
                        ></Login>
                    )}
                </div>
            </BottomSheet>
            {otpVerification.isOpen && (
                <div className="fixed top-0 left-0 w-full h-[100dvh] bg-white z-[1000000]">
                    <OTPVerify
                        setOtpVerification={setOtpVerification}
                        closeOtpVerification={closeOtpVerification}
                    ></OTPVerify>
                </div>
            )}
        </>
    );
};

export default LogInSignUpButtons;
