import CrossIcon from '@/components/svgs/CrossIcon';
import { CSSTransition } from 'react-transition-group';

const ErrorComp = ({ isError, message, error }) => {
    return (
        <CSSTransition
            in={isError}
            appear
            classNames={'error-'}
            unmountOnExit
            timeout={300}
        >
            <div className="py-2">
                <div className="bg-red-500 text-white rounded-lg py-2 px-4">
                    <CrossIcon className="text-white float-left"></CrossIcon>
                    <p className="text-center">{error?.message}</p>
                </div>
            </div>
        </CSSTransition>
    );
};

export default ErrorComp;
