import SubMenu from '../SubMenu';

const MyFavouriteSubMenu = ({ handleCloseSubMenu }) => {
    return (
        <SubMenu title={'My Favourite'} handleCloseSubMenu={handleCloseSubMenu}>
            <p>
                My Favourite Sub Menu - Lorem ipsum dolor, sit amet consectetur
                adipisicing elit. Earum, fugit molestiae similique perspiciatis
                aliquam voluptatibus! Magnam blanditiis libero totam deleniti
                adipisci, minus hic iure enim recusandae a explicabo
                voluptatibus ullam.
            </p>
        </SubMenu>
    );
};

export default MyFavouriteSubMenu;
