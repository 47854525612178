const LeftArrow = (props) => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        {...props}
    >
        <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={24}
            d="M244 400 100 256l144-144M120 256h292"
        />
    </svg>
);
export default LeftArrow;
