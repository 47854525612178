import { apiConnector } from '@/services/apiconnector';
import { BASE_SERVER_URI } from '@/services/apis';
import Image from 'next/image';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import CrossIcon from '../../svgs/CrossIcon';
import LinkItem from './LinkItem';
import './linkItem.css';
import BookingDetailsSubMenu from './SubMenus/BookingDetailsSubMenu';
import ContactUsSubMenu from './SubMenus/ContactUsSubMenu';
import EditSubMenu from './SubMenus/EditSubMenu';
import FAQsSubMenu from './SubMenus/FAQsSubMenu';
import MyFavouriteSubMenu from './SubMenus/MyFavouriteSubMenu';
const Profile = ({ userData, closeMenu, setUserData }) => {
    const [isEdit, setIsEdit] = useState(false);
    const handleCloseSubMenu = () => {
        setIsEdit(false);
    };

    const reqLogout = async () => {
        try {
            const res = await apiConnector(
                'get',
                `${BASE_SERVER_URI}/auth/logout`,
            );

            if (!res?.data.success) {
                throw Error(res?.data.message);
            }
            console.log('Logging OUT Data', res.data);
            setUserData(null);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="fixed h-[100dvh] py-8 w-full bg-white top-0 left-0">
            <button className="float-right mr-8 " onClick={closeMenu}>
                <CrossIcon></CrossIcon>
            </button>

            {/* profile */}
            <div className="flex justify-center items-center py-4 mt-7 flex-col ">
                <div className="relative">
                    <div className="borderr-2 border-[#ff612c] w-fit h-fit p-1 rounded-full">
                        <Image
                            loader={() => userData?.profile?.image}
                            src={userData?.profile?.image}
                            alt="name"
                            width={80}
                            height={80}
                            className="rounded-full bg-gray-200 "
                        ></Image>{' '}
                    </div>

                    <button
                        className="w-[30px] text>-gray-600 font-giest-light absolute bottom-[8px] -right-[4px] h-[30px] rounded-full bg-white flex items-center justify-center borderr-2 border-transparent border-b-[#ff612c] border-r-[#ff612c] rotate-[-10deg]"
                        onClick={() => setIsEdit(true)}
                    >
                        <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth={2}
                            viewBox="-2 -4 34 34"
                            className="w-[20px] h-[20px] pl-1 "
                        >
                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                        </svg>
                    </button>
                </div>

                <h2 className="text-gray-800 text-center mt-2">
                    {userData?.profile.displayName}
                </h2>
                <h2 className="text-gray-600 font-giest-light text-center text-sm">
                    {userData?.email}
                </h2>
            </div>

            <div className="mt-4 flex flex-col gap-y-0 max-w-maxContentTab mx-auto">
                <LinkItem subMenu={<BookingDetailsSubMenu />}>
                    Your Bookings
                </LinkItem>
                <LinkItem subMenu={<MyFavouriteSubMenu />}>
                    My Favourite
                </LinkItem>
                <LinkItem subMenu={<ContactUsSubMenu />}>Contact Us</LinkItem>
                <LinkItem subMenu={<FAQsSubMenu />}>FAQs</LinkItem>
            </div>

            <div className="mt-16 absolute bottom-0 w-full px-6">
                <hr />
                <div className="flex justify-center items-center py-4 flex-col">
                    <button
                        onClick={reqLogout}
                        className="text-sm text-gray-600 font-giest-light mx-auto mb-3"
                    >
                        Logout
                    </button>
                    {/* <span className="text-xs text-gray-300 text-center font-giest-light">
                        {' '}
                        adusdadyertweyae4e6e532
                    </span> */}
                </div>
            </div>

            <CSSTransition
                in={isEdit}
                classNames={'sub_menu'}
                timeout={800}
                appear
                unmountOnExit
            >
                {
                    <EditSubMenu
                        handleCloseSubMenu={handleCloseSubMenu}
                        userData={userData}
                        setUserData={setUserData}
                    ></EditSubMenu>
                }
            </CSSTransition>

            {/* Edit sub page */}
        </div>
    );
};

export default Profile;

const add = (num) => (num2) => num2 ? add(num + num2) : num;

// const add = (num) => {
//     const num2fn = (num2) => {
//         return num2 ? add(num + num2) : num;
//     };
//     return num2fn;
// };

const sum = add(1)(2)();
