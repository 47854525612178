import React from "react";

const FilterModalChild = ({ filterValue,handleFilter,filterValueQuery, }) => {
  return (
    <div className="mx-auto select-none">
      <div className=" flex-wrap w-full px-1 gap-y-1 mt-4 mx-auto grid grid-cols-2">
        {filterValue.map((item, index) => {
          return (
            <div
              key={index}
              className="flex items-center py-2 text-richblack-700 cursor-pointer "
            >
              <input
                key={index}
                type="radio"
                id={"checkbox_" + index}
                name="normalfilter"
                value={item.name}
                onChange={(e) => handleFilter(e, item)}
                checked={
                  // Object?.entries(filterValue)[0][0] ==
                  // "checkbox_" + index
                  `${filterValueQuery}` === `${item.name}`
                }
              />
              <label
                htmlFor={"checkbox_" + index}
                className={`text-sm select-none GTE_light cursor-pointer pl-3 relative ${
                  filterValueQuery === item.name && "text-[#ff612c]"
                }`}
              >
                {item.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterModalChild;
