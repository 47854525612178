"use client";
import react, { createContext } from "react";

export const DataContext = createContext();
const DataContextComp = ({ data, children }) => {
  return (
    <DataContext.Provider value={data}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContextComp;
