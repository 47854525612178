'use client';

import ActionButton from '@/components/ActionButton';
import ChevronDown from '@/components/svgs/ChevronDown';
import { BASE_SERVER_URI } from '@/services/apis';
import { login } from '@/services/operations/auth';
import styles from '@/styles/authform.module.css';
import escapeChar from '@/utils/escapeChar';
import { clsx } from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import ErrorComp from '../../ErrorComp';
import './style.css';

const Login = ({ setIsSignUp, setOpenAuthModal, setUserData }) => {
    const [step, setStep] = useState(1);
    const [error, setError] = useState(null);
    const inputsContainer = useRef(null);
    const [loginData, setLoginData] = useState({});
    const [isValidated, setIsValidated] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const inputContainer = inputsContainer.current;
        if (step == 1 && inputContainer) {
            inputContainer.scroll({
                left: 0,
                behavior: 'smooth',
            });
        }
        if (step == 2 && inputContainer) {
            const InputWidth = inputContainer.offsetWidth;
            const gap = escapeChar(window.getComputedStyle(inputContainer).gap);
            inputContainer.scroll({
                left: InputWidth + gap,
                behavior: 'smooth',
            });
        }

        console.log('called', step);
    }, [step]);

    const handleLoginData = (e) => {
        const element = e.currentTarget;
        const value = element.value;
        const name = element.name;
        setLoginData((prev) => ({ ...prev, [name]: value }));
        console.log(loginData['email'] && loginData['password']);
        if (loginData['email'] && loginData['password']) {
            setIsValidated(true);
            console.log('valdated');
        } else {
            setIsValidated(false);
        }
        setError(null);
    };

    const handleSubmitForm = async (e) => {
        console.log('form submitted', loginData);
        e.preventDefault();

        if (loginData.email && loginData.password) {
            // try {
            //     const res = await apiConnector('POST', auth.login, {
            //         ...loginData,
            //     });
            //     console.log(res);
            //     if (!res.data.success) {
            //         throw Error('Something Went Wrong');
            //     }
            //     console.log('loggedIn sucessfully');
            // } catch (error) {
            //     console.log(error);
            // }
            await login(
                loginData.email,
                loginData.password,
                setOpenAuthModal,
                setUserData,
                setError,
                setLoader,
                setIsSignUp,
            );
        }
        // alert('form Submitted' + loginData['email'] + loginData['password']);
    };

    console.log(step === 2 && isValidated);
    const checkUserInDB = async () => {
        let result;
        try {
            const isSignUp = Math.round(Math.random());
            setLoader(true);
            new Promise((res, rej) => {
                setTimeout(res, 400);
            });
            setLoader(false);
            result = true;
        } catch (err) {
            console.log(err);
        }
        return result;
    };

    const handleContinue = async () => {
        console.log('form continue');

        const emailElement = emailRef.current;
        const passwordElement = passwordRef.current;

        if (emailElement && emailElement.checkValidity()) {
            let checkUserPresence = await checkUserInDB();
            console.log('hello', checkUserPresence);
            if (checkUserPresence) {
                setError(null);
                setStep(2);
                if (passwordElement && passwordElement.checkValidity()) {
                    setIsValidated(true);
                    return;
                }
                if (step == 2) {
                    passwordRef.current.reportValidity();
                }
                return;
            }

            // setIsSignUp(true);

            return;
        }

        emailRef.current.reportValidity();
    };
    return (
        <div key={'login-form-key'}>
            <h1 className="text-left text-lg mb-3 mt-6 px-7 text-gray-800">
                Welcome! Login to continue
            </h1>
            <p className="text-gray-600 text-sm px-8 leading-5 mb-3 font-giest-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis
                exercitationem architecto accusamus.?
            </p>
            <form
                className={clsx('mt-4 text-sm ', styles.auth_form)}
                onSubmit={(e) => handleSubmitForm(e)}
            >
                <div>
                    <div
                        className={'flex gap-x-4 overflow-hidden scroll-smooth'}
                        ref={inputsContainer}
                    >
                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            required
                            className={clsx(
                                'min-w-full w-full',
                                styles.text_input,
                            )}
                            onChange={handleLoginData}
                            value={loginData['email']}
                            ref={emailRef}
                        />

                        <div className="relative min-w-full">
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                required
                                className={clsx(
                                    'min-w-full w-full ',
                                    styles.text_input,
                                    error && 'cursor-not-allowed',
                                )}
                                onChange={handleLoginData}
                                value={loginData['password']}
                                ref={passwordRef}
                                disabled={error}
                            />
                            {!error && (
                                <span
                                    className="absolute top-4 right-3 cursor-pointer"
                                    onClick={() =>
                                        passwordRef.current.type === 'text'
                                            ? (passwordRef.current.type =
                                                  'password')
                                            : (passwordRef.current.type =
                                                  'text')
                                    }
                                >
                                    See
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {step == 2 && error && (
                    <ErrorComp
                        isError={error}
                        message={error.message}
                        error={error}
                    />
                )}

                <ActionButton
                    type={step === 2 && isValidated ? 'submit' : 'button'}
                    className={clsx(
                        styles.submit_button,
                        '!w-fit mx-auto !bg-transparent !text-gray-800 !shadow-none border !border-gray-200 relative !px-8',
                    )}
                    onClick={(e) => {
                        handleContinue();
                    }}
                >
                    {!loader &&
                        (step == 2 && isValidated ? 'Submit' : 'Continue')}
                    {loader && 'loading...'}
                </ActionButton>

                {step == 2 && (
                    <p
                        onClick={() => {
                            setStep(1);
                            setError(null);
                            setIsValidated(false);
                            setLoginData((prev) => ({
                                ...prev,
                                ['password']: '',
                            }));
                        }}
                        className="text-center py-2 mt-2 text-gray-600 font-giest-light flex justify-center items-center gap-x-3 cursor-pointer hover:bg-gray-100 rounded-2xl w-fit px-4 mx-auto"
                    >
                        <ChevronDown className="rotate-90 text-gray-600"></ChevronDown>
                        Go Back
                    </p>
                )}
            </form>
            <div className="flex gap-x-4 items-center justify-center mt-8">
                <hr className="min-w-[120px] md:min-w-[160px] " />
                <p className="text-sm text-gray-500">OR</p>
                <hr className="min-w-[120px] md:min-w-[160px] " />{' '}
            </div>
            <div className="flex justify-center items-center my-6 px-5 w-full gap-x-12">
                <Link
                    href={`${BASE_SERVER_URI}/auth/google`}
                    className="text-center rounded-full border flex justify-center items-center w-[46px] hover:bg-gray-100 h-[46px] "
                >
                    <Image
                        src={'/images/google_logo.png'}
                        width={50}
                        height={50}
                        className="w-[25px] h-[25px] "
                    ></Image>
                    {/* <div className="basis-full rounded-full py-[0.7rem] px-4 cursor-pointer">
                        Google
                    </div> */}
                </Link>
                <Link
                    href={`${BASE_SERVER_URI}/auth/google/callback`}
                    className=" text-center rounded-full border flex justify-center items-center w-[46px] hover:bg-gray-100 h-[46px] "
                >
                    <Image
                        src={'/images/facebook_logo.png'}
                        width={50}
                        height={50}
                        className="w-[25px] h-[25px] "
                    ></Image>
                    {/* <div className="basis-full rounded-xl py-[0.7rem] px-4 cursor-pointer">
                        Facebook
                    </div> */}
                </Link>
            </div>

            <div
                className="text-sm hover:underline text-gray-700 text-center py-3 cursor-pointer"
                onClick={() => setIsSignUp(true)}
            >
                Want to Sign Up or register
            </div>
        </div>
    );
};

export default Login;
