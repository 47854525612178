import RatingStars from '@/components/core/RatingStars';
import FilterModal from '@/components/FilterModal';
import ChevronDown from '@/components/svgs/ChevronDown';
import { DataContext } from '@/context/DataContext';
import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';
import SubMenu from '../SubMenu';

const BookingDetailsSubMenu = ({ handleCloseSubMenu }) => {
    const [loading, setLoading] = useState(false);
    const { tours } = useContext(DataContext);
    const [filterModal, setFilterModal] = useState(false);

    console.log(tours);
    const delay = 2000;

    useEffect(() => {
        // setLoading(true);
        setTimeout(() => setLoading(false), delay);
    }, []);

    const handleCloseModal = () => setFilterModal(false);
    return (
        <SubMenu
            title={'Booking Details'}
            handleCloseSubMenu={handleCloseSubMenu}
        >
            <div className="sticky top-0 bg-gray-100 grid grid-cols-2 text-sm font-giest-light divide-x-2">
                <div className="text-center py-2">Sort By</div>
                <div
                    className="text-center flex gap-x-2 items-center justify-center cursor-pointer hover:bg-gray-200 py-2"
                    onClick={() => setFilterModal(true)}
                >
                    <span>Filter</span>
                    <ChevronDown></ChevronDown>
                </div>
            </div>
            {/* Initial Loader */}
            <div className="mt-4 relative">
                {loading && (
                    <div className="absolute w-full h-[110px] flex justify-center items-center">
                        <div className="w-[22px] h-[22px] rounded-full border-2 border-gray-200 border-l-[#ff612c] animate-spin select-none mx-auto "></div>
                    </div>
                )}
            </div>

            {/* Tours */}
            {!loading && (
                <section>
                    <h1>Tours</h1>

                    <div>
                        {!loading && (
                            <div className="my-1">
                                <TourItemCard
                                    status={'processing'}
                                ></TourItemCard>
                                <TourItemCard
                                    status={'completed'}
                                ></TourItemCard>
                                <TourItemCard
                                    status={'canceled'}
                                ></TourItemCard>
                            </div>
                        )}
                    </div>
                </section>
            )}

            {/* Hotels if exists */}
            <section className="mt-3">
                <h1>Hotels</h1>
                <div className="mt-3">
                    <HotelItemCard></HotelItemCard>
                    <HotelItemCard></HotelItemCard>
                    <HotelItemCard></HotelItemCard>
                </div>
            </section>

            {filterModal && (
                <FilterModal handleCloseModal={handleCloseModal}></FilterModal>
            )}
        </SubMenu>
    );
};

// Tour Card
const TourItemCard = ({ status }) => {
    const statusElement = useRef(null);

    switch (status) {
        case 'processing':
            statusElement.current = <ProcessingTag></ProcessingTag>;
            break;

        case 'completed':
            statusElement.current = <CompletedTag></CompletedTag>;
            break;

        case 'canceled':
            statusElement.current = <CancelTag></CancelTag>;
            break;

        default:
            break;
    }

    return (
        <div className="flex gap-x-4 items-start py-2 w-full max-w-maxContentTab">
            <Image
                loader={() => ''}
                src={''}
                width={100}
                height={100}
                className="rounded-xl bg-gray-300 w-[100px] h-[100px]"
            ></Image>
            <div className="flex-grow">
                <div className="mb-1 flex justify-between items-center">
                    <div>{statusElement.current}</div>

                    <div>⁝</div>
                </div>

                <h1 className="text-sm">
                    Unforgattable Adventure: Dubai Drifters Late Night tour
                </h1>
                <div className="flex flex-wrap gap-x-2 items-center mt-2">
                    <span className="text-[11px] font-giest-light bg-gray-200 rounded-full px-2 py-[2px]">
                        Adventure
                    </span>
                </div>
            </div>
        </div>
    );
};

// Hotel Item Card
const HotelItemCard = () => {
    return (
        <div className="py-2">
            <Image
                loader={() => ''}
                src={''}
                width={240}
                height={180}
                className="w-full rounded-xl bg-gray-200 h-[180px]"
            ></Image>

            <div className="px-3 py-4">
                <h1 className=" text-gray-900 text-base">
                    Hotel Red Bull Gives You wings - Europe
                </h1>
                <div className="flex gap-x-1 items-center">
                    <RatingStars
                        ratings={5}
                        className={'mt-2 text-sm'}
                    ></RatingStars>
                </div>
            </div>
        </div>
    );
};

// tags
const ProcessingTag = () => {
    return (
        <p className="flex gap-x-1 items-center mt-2">
            <span className="w-[4px] h-[4px] bg-yellow-600 rounded-full"></span>
            <span className="text-yellow-600 text-xs font-giest-light">
                Processing
            </span>
        </p>
    );
};
const CompletedTag = () => {
    return (
        <p className="flex gap-x-1 items-center mt-2">
            <span className=" text-green-600 rounded-full text-xs">✓</span>
            <span className="text-green-600 text-xs font-giest-light">
                Completed
            </span>
        </p>
    );
};
const CancelTag = () => {
    return (
        <p className="flex gap-x-1 items-center mt-2">
            <span className="text-red-600 text-xs font-giest-light">
                Canceled
            </span>
        </p>
    );
};

export default BookingDetailsSubMenu;
