"use client";

import clsx from "clsx";

const ActionButton = ({ children, onClick, className, type, disable }) => {
  return (
    <button
      type={type}
      // variant="outlined"
      className={clsx(
        `px-4 py-2 mt-1 md:mt-0 rounded-xl text-sm border-[#ff612c] bg-[#ff612c] text-white shadow-none hover:shadow-lg flex gap-x-2 items-center justify-center font-normal normal-case w-fit `,
        className,
        disable && '!bg-neutral-200 text-neutral-800 border-neutral-300 hover:shadow-none'
      )}
      onClick={() => onClick && onClick()}
      disabled={disable}
    >
      {children}
    </button>
  );
};

export default ActionButton;
