const NormalFilter = [
  {
    name: "No Filter",
  },
  {
    name: "Most Popular",
  },
  {
    name: "Newest",
  },
  {
    name: "Oldest",
  },
  {
    name: "Top Rated",
  },
  {
    name: "Cheapest",
  },
];
export default NormalFilter;