import SubMenu from '../SubMenu';

const FAQsSubMenu = ({ handleCloseSubMenu }) => {
    return (
        <SubMenu title={'FAQs'} handleCloseSubMenu={handleCloseSubMenu}>
            <p>
                Contact Us - Lorem ipsum dolor, sit amet consectetur adipisicing
                elit. Earum, fugit molestiae similique perspiciatis aliquam
                voluptatibus! Magnam blanditiis libero totam deleniti adipisci,
                minus hic iure enim recusandae a explicabo voluptatibus ullam.
            </p>
        </SubMenu>
    );
};

export default FAQsSubMenu;
