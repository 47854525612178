import axios from 'axios';
// import { setupCache } from 'axios-cache-adapter';

// const cache = {};
// const cacheDuration = 15 * 60 * 1000; // Cache duration in milliseconds (15 minutes)
// // Request interceptor to check the cache
// axios.interceptors.request.use((config) => {
//   const { url } = config;
//   const cached = cache[url];
//   if (cached) {
//     const now = Date.now();
//     if (now - cached.timestamp < cacheDuration) {
//       // Return cached response if it's still valid
//       config.adapter = () => Promise.resolve({
//         data: cached.data,
//         status: 200,
//         statusText: 'OK',
//         headers: cached.headers,
//         config,
//         request: {},
//       });
//     } else {
//       // Remove expired cache
//       delete cache[url];
//     }
//   }
//   return config;
// });

// // Response interceptor to store responses in the cache
// axios.interceptors.response.use((response) => {
//   const { config, data, headers } = response;
//   const { url } = config;
//   cache[url] = {
//     data,
//     headers,
//     timestamp: Date.now(),
//   };
//   return response;
// }, (error) => {
//   return Promise.reject(error);
// });

export const axiosInstance = axios.create();
export const apiConnector = async (method, url, bodyData, headers, params) => {
    // console.log(method, url, bodyData, headers, params)
    const responce = await axios({
        // Endpoint to send files
        url: url,
        method: `${method}`,
        headers: headers ? headers : null,
        // Attaching the form data
        data: bodyData ? bodyData : null,
        params: params ? params : null,

        withCredentials: true,
    })
        // Handle the response from backend here
        .then((res) => {
            // console.log(res.data);
            return res;
        })
        // Catch errors if any
        .catch((error) => {
            // console.log('err', error);
            return error.response;
        });

    return responce;
};
