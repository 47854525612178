import PrimeryHeading from '../core/PrimeryHeading';
import SearchIcon from '../svgs/SearchIcon';
import LogInSignUpButtons from './LogInSignUpButtons';

const UnAuthencatedNavbar = ({ setUserData }) => {
    return (
        <>
            <div className="px-4 flex items-stretch gap-x-3">
                <div className="px-4 flex items-stretch gap-x-3">
                    <button className="text-sm font-giest-regular rounded-xl py-[6px] px-2">
                        <SearchIcon></SearchIcon>
                    </button>
                </div>
                <LogInSignUpButtons
                    setUserData={setUserData}
                ></LogInSignUpButtons>
            </div>
        </>
    );
};

const NavbarBadge = ({ name, fill, Icon = <></>, className, children }) => {
    return (
        <li
            className={
                'px-3 py-[6px] text-gray-700 rounded-full flex gap-x-2 items-center basis-full relative min-w-fit' +
                className
            }
            style={{ background: fill }}
        >
            {Icon}
            <span className="font-giest-regular text-[13px] min-w-fit">
                {name}
            </span>

            {children}
        </li>
    );
};

const WhereToGoDropDown = () => {
    return (
        <div className="absolute w-[700px] max-w-maxContentTab bg-white rounded-xl drop-shadow-xl -bottom-[390px] min-h-[380px] -translate-x-1/3 border">
            <PrimeryHeading className={'!text-[20px] px-6'}>
                Find Tours
            </PrimeryHeading>
        </div>
    );
};

export default UnAuthencatedNavbar;
// <MapChartIcon className="inline-block mr-1 w-[20px]"></MapChartIcon>
