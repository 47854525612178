import ActionButton from '@/components/ActionButton';
import ErrorComp from '@/components22/common/ErrorComp';
import { updateProfile } from '@/services/operations/auth';
import Image from 'next/image';
import { useState } from 'react';
import SubMenu from '../SubMenu';

const EditSubMenu = ({ handleCloseSubMenu, userData, setUserData }) => {
    console.log(userData);
    const [inputData, setInputData] = useState({
        nickName: userData?.profile.displayName,
        email: userData?.email,
        bio: userData?.profile.bio,
        phoneNumber: userData?.profile.phoneNumber,
        image: userData?.profile?.image,
        imageFile: null,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setInputData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await updateProfile(
            inputData,
            setLoading,
            setError,
            setUserData,
            handleCloseSubMenu,
        );
    };
    return (
        <SubMenu handleCloseSubMenu={handleCloseSubMenu} title={'Edit'}>
            <ErrorComp isError={error} error={error} message={error?.message} />
            <form onSubmit={handleFormSubmit}>
                {/* <SubHeading title={'Change Avatar '} /> */}

                <div className="w-full flex justify-center items-center md:px-1">
                    <Image
                        loader={() =>
                            inputData.imageFile
                                ? URL.createObjectURL(inputData.imageFile)
                                : inputData.image
                        }
                        src={
                            inputData.imageFile
                                ? URL.createObjectURL(inputData.imageFile)
                                : inputData.image
                        }
                        width={80}
                        height={80}
                        className="bg-gray-200 rounded-full h-[80px] w-[80px] object-cover"
                        alt="profile image"
                    ></Image>

                    <label className="ml-5 border rounded-lg px-2 py-1">
                        <div className="text-sm text-gray-600 cursor-pointer">
                            Choose Avatar
                        </div>
                        <input
                            type="file"
                            className="hidden"
                            multiple={false}
                            disabled={loading}
                            onChange={(e) => {
                                console.log(e.target.files[0]);
                                setInputData((prev) => ({
                                    ...prev,
                                    imageFile: e.target.files[0],
                                }));
                            }}
                        ></input>
                    </label>
                </div>

                {/* <SubHeading title={'Personal Details'} /> */}
                <label className="mt-6 block">
                    <span className="text-sm mb-2 font-giest-light">
                        Name *
                    </span>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        name="nickName"
                        value={inputData && inputData.nickName}
                        className=" text-gray-700 w-full block border border-100 py-2 px-4 rounded-lg mt-2 font-giest-light text-sm outline-[#ff612c]"
                        onChange={handleInputChange}
                        required
                        disabled={loading}
                    />
                </label>
                <label className="mt-6 block">
                    <span className="text-sm mb-2 font-giest-light">
                        Email *
                    </span>
                    <input
                        type="email"
                        placeholder="Enter your Email"
                        name="email"
                        value={inputData && inputData.email}
                        className=" text-gray-700 w-full block border border-100 py-2 px-4 rounded-lg mt-2 font-giest-light text-sm focus:outline-[#ff612c]"
                        onChange={handleInputChange}
                        disabled
                    />
                </label>
                <label className="mt-6 block">
                    <span className="text-sm mb-2 font-giest-light">
                        Phone Number
                    </span>
                    <input
                        type="number"
                        placeholder="Enter your Phone Number"
                        name="phoneNumber"
                        value={inputData && inputData.phoneNumber}
                        className=" text-gray-700 w-full block border border-100 py-2 px-4 rounded-lg mt-2 font-giest-light text-sm focus:outline-[#ff612c]"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                </label>
                <label className="mt-6 block">
                    <span className="text-sm mb-2 font-giest-light">Bio</span>
                    <textarea
                        type="text"
                        placeholder="Enter your Bio"
                        name="bio"
                        value={inputData && inputData.bio}
                        className=" text-gray-700 w-full block border border-100 py-2 px-4 rounded-lg mt-2 font-giest-light text-sm focus:outline-[#ff612c]"
                        onChange={handleInputChange}
                        disabled={loading}
                    />
                </label>

                <div
                    className="flex justify-end items-center py-4"
                    onClick={() => {}}
                >
                    <ActionButton>
                        {!loading && 'Save Changes'}
                        {loading && 'Loading...'}
                    </ActionButton>
                </div>
            </form>
        </SubMenu>
    );
};
const SubHeading = ({ title }) => {
    return (
        <h3 className="mt-5 text-gray-400 text-sm font-giest-light mb-3">
            {title}
        </h3>
    );
};
export default EditSubMenu;
